import React, { useState, useEffect } from 'react'
import Page2 from '../homepage/page2/page2';

import CommonFooter from '../common_footer/common_footer'

// api 
import { ApiHelper } from '../../utilFunctions/utilfunction';
import Page6 from '../homepage/page6/page6';

const Watch_Part = () => {


    const [banner2, setBanner2] = useState();

    useEffect(async () => {

        let url2 = "/page_banner_image/watch_part_page_banner_image"
        let bannerdata = await ApiHelper(url2);
        setBanner2(bannerdata?.data["image_full_ path"]);
    }, []);

    var divStyle = {
        backgroundImage: 'url(' + banner2 + ')',

    };

    return (

        <section className='archive p-navbar'>

            <div className='d-flex justify-content-center align-items-center position-relative bannerimage w-100 h-auto text-center mb-5' style={divStyle}>
                <h2 className='page3_z-index2 fs-48 w-100 text-light pt-5 pb-5 fw-700'>Watch Parts</h2>
                <div className="overlay-landingpage"></div>
            </div>

            <Page6 />
            {/* <CommonFooter/> */}
        </section>
    )
};

export default Watch_Part;